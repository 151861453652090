import {RiArrowDownDoubleLine, RiArrowUpDoubleLine} from "react-icons/ri";
import CustomOverlay from "../Elements/CustomOverlay";
import SelectOperator from "../Elements/Select/SelectOperator";
import SelectPriority from "../Elements/Select/SelectPriority";
import SelectStatus from "../Elements/Select/SelectStatus";
import SelectTags from "../Elements/Select/SelectTags";
import {Link} from "react-router-dom";
import {TiDelete, TiEdit} from "react-icons/ti";
import {Table} from "react-bootstrap";
import React from "react";
import {formatDataItWithTime} from "../../Utils/DateFormat";

export const TicketTable = (props) => {
    return <Table responsive="sm" striped bordered hover>
        <thead className={''}>
        <tr>
            <th style={{cursor: "pointer"}} data-order={"sorts[title]"} data-type-order={props.typeOrder.title ? props.typeOrder.title : "desc"} data-column="title" onClick={props.handleOrder}>
                {props.typeOrder.title && props.typeOrder.title === 'desc' ? <RiArrowUpDoubleLine /> : <RiArrowDownDoubleLine />}
                Titolo
            </th>
            <th>Categoria</th>
            <th style={{cursor: "pointer"}} data-order={"sorts[description]"} data-type-order={props.typeOrder.description ? props.typeOrder.description : "desc"} data-column="description" onClick={props.handleOrder}>
                {props.typeOrder.description && props.typeOrder.description === 'desc' ? <RiArrowUpDoubleLine /> : <RiArrowDownDoubleLine />}
                Descrizione
            </th>
            <th>Ultimo Messaggio</th>
            { props.isAdmin && <th style={{cursor: "pointer"}} data-order={"sorts[operator_id]"} data-type-order={props.typeOrder.operator ? props.typeOrder.operator : "desc"} data-column="operator" onClick={props.handleOrder}>
                {props.typeOrder.operator && props.typeOrder.operator === 'desc' ? <RiArrowUpDoubleLine /> : <RiArrowDownDoubleLine />}
                Operatore
            </th> }
            <th style={{cursor: "pointer"}} data-order={"sorts[priority_id]"} data-type-order={props.typeOrder.priority ? props.typeOrder.priority : "desc"} data-column="priority" onClick={props.handleOrder}>
                {props.typeOrder.priority && props.typeOrder.priority === 'desc' ? <RiArrowUpDoubleLine /> : <RiArrowDownDoubleLine />}
                Priorità
            </th>
            <th style={{cursor: "pointer"}} data-order={"sorts[status_id]"} data-type-order={props.typeOrder.status ? props.typeOrder.status : "desc"} data-column="status" onClick={props.handleOrder}>
                {props.typeOrder.status && props.typeOrder.status === 'desc' ? <RiArrowUpDoubleLine /> : <RiArrowDownDoubleLine />}
                Status
            </th>
            {props.isAdmin && <th>Tags</th> }
            <th style={{cursor: "pointer"}} data-order={"sorts[user_id]"} data-type-order={props.typeOrder.user ? props.typeOrder.user : "desc"} data-column="user" onClick={props.handleOrder}>
                {props.typeOrder.user && props.typeOrder.user === 'desc' ? <RiArrowUpDoubleLine /> : <RiArrowDownDoubleLine />}
                Segnalatore
            </th>
            <th>Data Creazione</th>
            <th>Data Modifica</th>
            <th>Azioni</th>
        </tr>
        </thead>
        <tbody>
        {props.tickets && props.tickets.length > 0 ? props.tickets.map((i, rowIndex) => {
            return (
                <tr data-id={i.id} key={i.id}>
                    <td className={'align-middle'}><p className={'mb-0'}>{i.title}</p><span className={'badge bg-secondary mx-2'}>#{i.code}</span></td>
                    <td className={'align-middle'}><span className={'badge'} style={{backgroundColor: i.category.hex_code}}>{i.category.name}<br /><hr className={'m-2'} />{i.subcategory.name}</span></td>
                    <td className={'align-middle'}><CustomOverlay text={i.description} /></td>
                    <td className={'align-middle'}>{i.messages && i.messages.length > 0 ? (<CustomOverlay text={i.messages[i.messages.length - 1].message} />) : 'Nessun Messaggio'}</td>
                    {props.isAdmin && <td className={'align-middle'}><SelectOperator ticket={i} rowIndex={0} operators={props.operators} isAdmin={props.isAdmin} roles={props.roles} /></td>}
                    <td className={'align-middle'}><SelectPriority ticket={i} rowIndex={2} priorities={props.priorities} isAdmin={props.isAdmin} roles={props.roles} /></td>
                    <td className={'align-middle'}><SelectStatus ticket={i} rowIndex={3} statuses={props.statuses} isAdmin={props.isAdmin} roles={props.roles} /></td>
                    {props.isAdmin && <td className={'align-middle'}><SelectTags ticket={i} rowIndex={1} tags={props.tags} isAdmin={props.isAdmin} roles={props.roles}/></td>}
                    <td className={'align-middle'}>{i.user.username}</td>
                    <td className={'align-middle'}>{formatDataItWithTime(new Date(i.created_at))}</td>
                    <td className={'align-middle'}>{formatDataItWithTime(new Date(i.updated_at))}</td>
                    <td className={'align-middle'}>
                        <Link to={`/ticket/${i.id}`}><TiEdit className={'fs-4'} /></Link>
                        <TiDelete className={'fs-4'} data-id={i.id} onClick={props.handleDelete} cursor={"pointer"}/>
                    </td>
                </tr>
            )
        }) : (
            <tr>
                <td colSpan={props.isAdmin ? 12 : 10} className={"justify-content-center"}>Nessun Ticket</td>
            </tr>
        )}
        </tbody>
    </Table>
}