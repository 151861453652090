import './App.css';
import Layout from "./Components/Layout/Layout";
import {AuthContextProvider} from "./Context/authContext";
import { connect } from 'react-redux';

function App() {
    return (
        <AuthContextProvider>
            <div className="App">
                <Layout/>
            </div>
        </AuthContextProvider>
    );
}

export default connect()(App);
