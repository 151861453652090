import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import GoBack from "../../Components/Elements/GoBack";
import {Card, Col, Container, Row, Tabs, Tab, Button} from "react-bootstrap";
import {addMessageAndFileToTicket, ticketDetail, ticketHistory} from "../../Api/Tickets/FetchData";
import Timeline from "../../Components/Elements/Timeline";
import CustomAlert from "../../Components/Elements/CustomAlert";
import SelectOperator from "../../Components/Elements/Select/SelectOperator";
import {retrieveTags} from "../../Api/Tickets/FetchTags";
import {retrieveOperators} from "../../Api/Tickets/FetchOperators";
import SelectTags from "../../Components/Elements/Select/SelectTags";
import SelectPriority from "../../Components/Elements/Select/SelectPriority";
import SelectStatus from "../../Components/Elements/Select/SelectStatus";
import {downloadAttachments} from "../../Api/Tickets/FetchAttachments";
import {IsAdmin, Roles} from "../../Utils/Roles";
import {IoPaperPlane} from "react-icons/io5";
import {Loading} from "../../Components/Elements/Loading";
import {useDispatch, useSelector} from "react-redux";

const Detail = () => {
    const dispatch = useDispatch();
    const userId = JSON.parse(localStorage.getItem('userData')).id ?? null;
    const {id } = useParams();
    const [message, setMessage] = useState()
    const [ticket, setTicket] = useState();
    const [history, setHistory] = useState()
    const [tags, setTags] = useState([]);
    const [priorities, setPriorities] = useState([])
    const [categories, setCategories] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [operators, setOperators] = useState([]);
    const [update, setUpdate] = useState(false);
    const [errors, setErrors] = useState({});
    const [roles, setRoles] = useState()
    const [isAdmin, setIsAdmin] = useState()
    const [loading, setIsLoading] = useState(false)
    //redux selector
    const {dataCategories} = useSelector((state) => state.category);
    const {dataPriorities} = useSelector((state) => state.priority);
    const {dataStatuses} = useSelector((state) => state.status);

    //retrieve necessary information list of tags, priority, category and operators at inizialization component
    useEffect(() => {
        setCategories(dataCategories)
        setPriorities(dataPriorities)
        setStatuses(dataStatuses)
    }, [dataStatuses, dataCategories, dataPriorities])

    //retrieve ticket detail on load page
    useEffect(() => {
        ticketDetail(id, {setTicket, setMessage, setIsLoading})
        ticketHistory(id, {setHistory, setMessage})
        retrieveTags({setTags, setMessage})
        retrieveOperators({setOperators, setMessage})
        setRoles(Roles)
        setIsAdmin(IsAdmin)
    }, [id, update]);

    //update ticket after almost change
    useEffect(() => {
        setUpdate(false);
    }, [update]);

    //Add message and file to ticket
    const handleOnSubmit = (e, {setUpdate}) => {
        e.preventDefault();
        setIsLoading(true);
        const form = document.getElementById('addMessageTicketForm');
        const formData = new FormData(form);
        addMessageAndFileToTicket(formData, id, setMessage, setErrors, dispatch);

        setTimeout(() => {
            setUpdate(true)
            setIsLoading(false)
        }, 500)
    }

    //download image
    const handleClickImage = (event) => {
        event.preventDefault();
        const id = event.target.getAttribute('data-id');
        const filename = event.target.getAttribute('data-filename')
        downloadAttachments(id, filename, {setMessage})
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs={2} className={"text-start"}>
                        <GoBack url={'/tickets'} /> <span className={'badge bg-secondary mx-2'}>#{ticket && ticket.code}</span>
                    </Col>
                </Row>
                <Row className={"my-3"}>
                    <Col xs={12} className={"text-start"}>
                        <h3>{ticket && ticket.title}</h3>
                    </Col>
                </Row>
            </Container>

            <CustomAlert message={message} />

            <Container fluid>
                <Row>
                    <Col xs={8} className={"text-start"}>
                        <Card>
                            <Card.Header as="h5">Descrizione</Card.Header>
                            <Card.Body>
                                {ticket && ticket.description}
                            </Card.Body>
                        </Card>

                        <Tabs defaultActiveKey="message" id="tabs-ticket" className="my-2" fill>
                            <Tab eventKey="message" title="Messaggi">
                                <Row className={"mb-2 d-flex align-content-end justify-content-end"}>
                                    <form id="addMessageTicketForm" onSubmit={(event) => handleOnSubmit(event, {setUpdate})} encType={'multipart/form-data'}>
                                        <input className={'form-control-sm form-control'} type={'hidden'} name={'user_id'} value={userId}/>

                                        <Col sm={12}>
                                            <textarea className={'form-control'} name={'message'} placeholder={'Messaggio'}/>
                                            { errors && errors.message && (<span className={"text-danger"}>{errors.message}</span>) }
                                        </Col>

                                        <Col sm={12} className={"mt-2"}>
                                            <input accept={".docx, .pdf, .jpeg, .jpg"} className={'form-control'} name={'files[]'} type={'file'} multiple={'multiple'}/>
                                            { errors && errors.files && (<span className={"text-danger"}>{errors.files}</span>) }
                                        </Col>

                                        <Col className={"mt-2 d-flex align-content-end justify-content-end"}>
                                            <Button type={"submit"} className={"btn btn-primary"}>Invia <IoPaperPlane /></Button>
                                        </Col>
                                    </form>
                                </Row>
                                <hr></hr>
                                <Timeline ticket={ticket} handleClickImage={handleClickImage}/>
                            </Tab>
                            <Tab eventKey="history" title="History">
                                <Timeline history={history} />
                            </Tab>
                        </Tabs>
                    </Col>

                    <Col xs={4} className={"text-start"}>
                        <Card>
                            <Card.Header as="h5">Dettagli</Card.Header>
                            <Card.Body>
                                <Container className={'small'}>
                                    <Row className={"mb-5"}>
                                        <Col sm={6}>
                                            <span className={"fw-bold"}>Stato:</span>
                                        </Col>
                                        <Col sm={6} >
                                            {ticket && <SelectStatus ticket={ticket} rowIndex={3} statuses={statuses} isAdmin={isAdmin} roles={roles}/>}
                                        </Col>
                                    </Row>
                                    <Row className={"mb-5"}>
                                        <Col sm={6}>
                                            <span className={"fw-bold"}>Priorità:</span>
                                        </Col>
                                        <Col sm={6}>
                                            {ticket && <SelectPriority ticket={ticket} rowIndex={2} priorities={priorities} isAdmin={isAdmin} roles={roles}/>}
                                        </Col>
                                    </Row>
                                    <Row className={"mb-5"}>
                                        <Col sm={6}>
                                            <span className={"fw-bold"}>Operatore/i:</span>
                                        </Col>
                                        <Col sm={6}>
                                            {ticket && <SelectOperator ticket={ticket} rowIndex={0} operators={operators} isAdmin={isAdmin} roles={roles}/> }
                                        </Col>
                                    </Row>
                                    <Row className={"mb-5"}>
                                        <Col sm={6}>
                                            <span className={"fw-bold"}>Segnalatore:</span>
                                        </Col>
                                        <Col sm={6}>
                                            {ticket && <Container className={"text-start"}>{ticket && ticket.user.username}</Container> }
                                        </Col>
                                    </Row>
                                    <Row className={"mb-5"}>
                                        <Col sm={6}>
                                            <span className={"fw-bold"}>Categoria:</span>
                                        </Col>
                                        <Col sm={6}>
                                            <Container className={"text-start"}><span className={'badge'} style={{backgroundColor: ticket && ticket.category.hex_code}}>{ticket && ticket.category.name} - {ticket && ticket.subcategory.name}</span></Container>
                                        </Col>
                                    </Row>
                                    {isAdmin && <Row>
                                        <Col sm={6}>
                                            <span className={"fw-bold"}>Tags:</span>
                                        </Col>
                                        <Col sm={6}>
                                            {ticket && <SelectTags ticket={ticket} rowIndex={1} tags={tags} isAdmin={isAdmin} roles={roles} /> }
                                        </Col>
                                    </Row> }
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            { loading && <Loading /> }
        </>
    )
}

export default Detail