import Select from "react-select";
import React, {useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {RiArrowDownDoubleLine, RiArrowLeftRightFill, RiArrowUpDoubleLine} from "react-icons/ri";
import {ticketUpdate} from "../../../Api/Tickets/FetchData";
import { useDispatch } from 'react-redux';

const SelectPriority = (props) => {
    const dispatch = useDispatch();
    const ticket = props.ticket;
    const rowIndex = props.rowIndex;
    const [message, setMessage] = useState()
    const [priority, setPriority] = useState({"value": ticket.priority.id, "label": ticket.priority.name, "color": ticket.priority.hex_code});
    const [focusedRowIndex, setFocusedRowIndex] = useState(-1);
    const [focusedColumnIndex, setFocusedColumnIndex] = useState(-1);
    const selectRef = useRef(null);
    const [loading, setIsLoading] = useState(false)

    const handleFocus = (rowIndex, columnIndex) => {
        setFocusedRowIndex(rowIndex);
        setFocusedColumnIndex(columnIndex);
    };

    const handleBlur = () => {
        setFocusedRowIndex(-1);
        setFocusedColumnIndex(-1);
    };

    //assign tag for ticket (not save)
    const handleSelectAdd = (newValue, ticketId) => {
        ticketUpdate(ticketId, newValue.value, 'priority_id', {setMessage, setIsLoading, dispatch});
        setPriority(newValue)
    }

    //create object to pass in a select
    const priorityList = props.priorities.map(priority => {
        return {value: priority.id, label: priority.name, color: priority.hex_code}
    })

    return (
        rowIndex === focusedRowIndex && focusedColumnIndex === 2  ? (
            <div ref={selectRef} className={'inline-block'} onBlur={handleBlur}>
                <Select classNamePrefix="react-select form-plaint"
                        onChange={(
                            newValue,
                            actionMeta
                        ) => {
                            switch (actionMeta.action) {
                                case 'select-option':
                                    handleSelectAdd(newValue, ticket.id)
                                    break;
                            }}}
                        key={ticket.id} data-name={'priority_id'} options={priorityList} defaultValue={{label: ticket.priority.name, value: ticket.priority.id}}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        isClearable={false} placeholder={'Seleziona priorità'} className={'form-select-sm form-control-plaintext p-0'}>
                </Select>
            </div>
        ) : (
            <div onClick={() => {if(props.isAdmin) handleFocus(rowIndex, 2)}} className={'w-100 h-100 d-inline-block'}>
                {
                    <>
                        <Container className={"text-start"}>
                            {
                                (priority.value === 1 && (<RiArrowUpDoubleLine className={'fs-5'} color={priority.color} />)) ||
                                (priority.value === 2 && (<RiArrowUpDoubleLine className={'fs-5'} color={priority.color} />)) ||
                                (priority.value === 3 && (<RiArrowUpDoubleLine className={'fs-5'} color={priority.color} />)) ||
                                (priority.value === 4 && (<RiArrowLeftRightFill className={'fs-5'} color={priority.color} />)) ||
                                (priority.value === 5 && (<RiArrowDownDoubleLine className={'fs-5'} color={priority.color} />))
                            }
                            <span key={priority.value}> {priority.label}</span>
                        </Container>
                    </>
                }
            </div>
        )
    );
}

export default SelectPriority