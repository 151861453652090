import Select from "react-select";
import React, {useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {assignTags, removeTag} from "../../../Api/Tickets/FetchTags";

const SelectTags = (props) => {
    const ticket = props.ticket;
    const rowIndex = props.rowIndex;
    const [message, setMessage] = useState()
    const [assignTag, setAssignTag] = useState()
    const [focusedRowIndex, setFocusedRowIndex] = useState(-1);
    const [focusedColumnIndex, setFocusedColumnIndex] = useState(-1);
    const selectRef = useRef(null);
    const [loading, setIsLoading] = useState(false)

    //generate new operator ticket
    const [newTags, setNewTags] = useState(
        ticket.tags.map((element) => {
            return {label: element.name, value: element.id}
        })
    )

    const handleFocus = (rowIndex, columnIndex) => {
        setFocusedRowIndex(rowIndex);
        setFocusedColumnIndex(columnIndex);
    };

    const handleBlur = () => {
        setFocusedRowIndex(-1);
        setFocusedColumnIndex(-1);
    };

    //assign tag for ticket (not save)
    const handleSelectAdd = (newValue, ticketId) => {
        setAssignTag([newValue.map(op => { return op.value })]);
        setNewTags(newValue)
    }

    //Remove tag or operator by ticket id
    const handleSelectDelete = (event, ticketId, newValue) => {
        removeTag(ticketId, [event.value], {setMessage, setIsLoading});
        setAssignTag([]);
        setNewTags(newValue);
    }

    //Add tags to ticket
    const handleSelectBlur = (ticketId) => {
        if(assignTag.length > 0){
            assignTags({ticketId, assignTag, setMessage, setIsLoading})
            setAssignTag(newTags);
        }
    }

    //create object to pass in a select
    const tagList = props.tags.map(tag => {
        return {value: tag.id, label: tag.name}
    })

    return (
        rowIndex === focusedRowIndex && focusedColumnIndex === 1  ? (
            <div ref={selectRef} onBlur={handleBlur}>
                <Select classNamePrefix="react-select form-plaint"
                        onFocus={() => handleFocus(rowIndex, 1)}
                        onChange={(
                            newValue,
                            actionMeta
                        ) => {
                            switch (actionMeta.action) {
                                case 'remove-value':
                                    handleSelectDelete(actionMeta.removedValue, ticket.id, newValue)
                                    break
                                case 'select-option':
                                    handleSelectAdd(newValue, ticket.id)
                                    break;
                            }}}
                        onBlur={event => handleSelectBlur(ticket.id, event)}
                        key={ticket.id} data-name={'tag_id'} options={tagList} defaultValue={newTags} isMulti
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                border: '1px solid #dedede',
                            }),
                            multiValueLabel: (styles, {data}) => ({
                                ...styles,
                                backgroundColor: '#333',
                                color: '#fff'
                            }),
                            multiValueRemove: (styles, {data}) => ({
                                ...styles,
                                backgroundColor: '#333',
                                color: '#fff'
                            }),
                            dropdownIndicator: (styles, {}) => ({
                                ...styles,
                                display: 'none'
                            }),
                        }} isClearable={false} placeholder={'Seleziona tag'} className={'form-select-sm form-control-plaintext p-0'}>
                </Select>
            </div>

        ) : (
            <div onClick={() => {if(props.isAdmin) handleFocus(rowIndex, 1)}} className={'w-100 h-100 d-inline-block'}>
                {
                    newTags.length > 0
                        ? ( newTags.map((element) => {
                            return (
                                <Container key={element.value} className={"text-start"}>
                                    <span key={element.value} className={'badge mx-1 bg-warning d-inline-block'}>{element.label}</span>
                                </Container>
                            )
                        }))
                        : (
                            <Container className={"text-start"}>
                                <span className={' mx-1 d-inline-block text-light-grey small'}>Aggiungi tag...</span>
                            </Container>
                        )
                }
            </div>
        )
    );
}

export default SelectTags