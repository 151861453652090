import { combineReducers } from 'redux';
import categoryReducer from "./ReduceCategories";
import priorityReducer from "./ReducePriorities";
import statusReducer from "./ReduceStatuses";

const rootReducer = combineReducers({
    category: categoryReducer,
    priority: priorityReducer,
    status: statusReducer
    // Add more reducers as needed
});

export default rootReducer;