import {Form} from "react-bootstrap";
import {useEffect, useState} from "react";

const Search = (props) => {
    const [q, setQ] = useState(null)

    //set search
    useEffect(() => {
        if(q !== null && props.sendSearch === true){
            props.setSearch(search => ({
                ...search, [props.name]: q
            }));
        }
    }, [props, q])

    return (
        <Form.Control type={'text'} name={props.name} placeholder={props.placeholder} onBlur={(e) => setQ(e.target.value)}></Form.Control>
    )
}

export default Search