import {Col, Container, Row} from "react-bootstrap";
import {FaFileDownload} from "react-icons/fa";
import {Fragment} from "react";

const Timeline = ({ticket, history, handleClickImage}) => {
    return (
        <Container className={"containerScroll"}>
            <Row className={"d-flex justify-content-center mt-70 mb-70"}>
                <Col sm={12}>
                    {ticket && ticket.messages && ticket.messages.length > 0 && (
                        ticket.messages.map(message => { return (
                            <div key={message.id} className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                <div className="vertical-timeline-item vertical-timeline-element">
                                    <div>
                                        <span className="vertical-timeline-element-icon bounce-in">
                                            <i className={ticket.user_id === message.user_id ? 'p-2 badge bg-success' : 'p-2 badge bg-warning'}> </i>
                                        </span>
                                        <div className="vertical-timeline-element-content bounce-in">
                                            <h4 className="timeline-title">{message.user.username}</h4>
                                            <p>{message.message}</p>
                                            <p>{message.attachments && message.attachments.length > 0 && (
                                                message.attachments.map(attachment => { return (
                                                    <Fragment key={attachment.id}>
                                                        <a onClick={handleClickImage} href={'/'} data-id={attachment.id} data-filename={attachment.file_name}>
                                                            <FaFileDownload data-id={attachment.id} data-filename={attachment.file_name} className={"fs-6"} />
                                                            <span data-id={attachment.id} data-filename={attachment.file_name}>{attachment.file_name}</span>
                                                            <br></br>
                                                        </a>
                                                    </Fragment>
                                                )})
                                            )}</p>
                                            <span className="vertical-timeline-element-date">{new Date(message.created_at).toLocaleDateString()}</span>
                                            <br></br>
                                            <span className="vertical-timeline-element-time">{new Date(message.created_at).toLocaleTimeString()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )})
                    )}
                    {history && history.length > 0 && (
                        history.map(hs => { return (
                            <div key={hs.id} className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                                <div className="vertical-timeline-item vertical-timeline-element">
                                    <div>
                                        <span className="vertical-timeline-element-icon bounce-in">
                                            <i className={hs.user_id === hs.user_id_change ? 'p-2 badge bg-success' : 'p-2 badge bg-warning'}> </i>
                                        </span>
                                        <div className="vertical-timeline-element-content bounce-in">
                                            <h4 className="timeline-title">{hs.user_id === hs.user_id_change ? 'SEGNALATORE' : 'OPERATORE'}</h4>
                                            <p>{hs.change}</p>
                                            <span
                                                className="vertical-timeline-element-date">{new Date(hs.created_at).toLocaleDateString()}</span>
                                            <br></br>
                                            <span
                                                className="vertical-timeline-element-time">{new Date(hs.created_at).toLocaleTimeString()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        })
                    )}
                </Col>
            </Row>
        </Container>
    )
}

export default Timeline;