import axiosInstance from "../AxiosInstance";
import {retrievePriorityRedux} from "../../Redux/Actions/ActionPriority";
import {retrieveStatusesRedux} from "../../Redux/Actions/ActionStatus";
import {retrieveCategoryRedux} from "../../Redux/Actions/ActionCategory";

//retrieve ticket list
export const retrieveTicketList = ({ticketUrl, setTickets, setPaginationLink, search, setErrors, setIsLoading}) => {
    setIsLoading(true)
    const query = search ? Object.keys(search)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(search[key]))
        .join('&') : '';

    const symbol = ticketUrl.includes('?') ? '&' : '?';

    axiosInstance.get(ticketUrl + symbol + query).then((response) => {
        setTickets(response.data.data.data);
        setPaginationLink(response.data.data.links);
        setIsLoading(false)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            //setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            //setErrors(error)
            console.error('Error:', error);
        }
        setIsLoading(false)
    });
}

//add new ticket with message and file attachment
export const addNewTicket = (formData, addMessage, {setTickets, setPaginationLink, setMessage, ticketUrl, setShow, setErrors, setIsLoading, dispatch}) => {
    setIsLoading(true)
    axiosInstance.post('/api/tickets/v1/new', formData)
        .then((response) => {
                setMessage({'message': response.data.message, type: 'success'})
                setErrors('')
                if(addMessage) addMessageAndFileToTicket(formData, response.data.data.id, setMessage);
                retrieveTicketList({ticketUrl, setTickets, setPaginationLink, setIsLoading})
                setShow(false)
                setIsLoading(false)

                dispatch(retrievePriorityRedux())
                dispatch(retrieveStatusesRedux())
                dispatch(retrieveCategoryRedux())
        }).catch((error) => {
            if (error.response && error.response.status === 422) {
                // Handle the 422 response (validation error)
                setMessage({'message': error.response.data.message, type: 'danger'})
                setErrors(error.response.data.errors);
                console.log('Validation Errors:', error.response.data);
            } else {
                // Handle other errors (e.g., network issues)
                setMessage({'message': error.response, type: 'danger'})
                console.error('Error:', error);
            }
            setIsLoading(false)
        });
}

//add message and file attachment to ticket
export const addMessageAndFileToTicket = (formData, ticketId, setMessage, setErrors, dispatch) => {
    axiosInstance.post('/api/tickets/v1/' + ticketId + '/add-message', formData)
        .then((response) => {
            dispatch(retrieveStatusesRedux());
            setMessage({'message': response.data.message, type: 'success'})
        }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setMessage({'message': error.response.data.message, type: 'danger'})
            setErrors(error.response.data.errors);
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.error('Error:', error);
        }
    });
}

//remove a ticket
export const removeTicket = (ticketId, {ticketUrl, setTickets, setPaginationLink, setMessage, setIsLoading, dispatch}) => {
    setIsLoading(true)
    axiosInstance.delete('/api/tickets/v1/' + ticketId)
        .then((response) => {
            retrieveTicketList({ticketUrl, setTickets, setPaginationLink, setIsLoading})
            dispatch(retrieveStatusesRedux())
            dispatch(retrievePriorityRedux())
            dispatch(retrieveCategoryRedux())

            setMessage({'message': response.data.message, type: 'success'})
            setIsLoading(false)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            //setErrors(error.response.data.errors)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            //setErrors(error)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.error('Error:', error);
        }
        setIsLoading(false)
    })
}

//retrieve ticket details
export const ticketDetail = (ticketId, {setTicket, setMessage, setIsLoading}) => {
    setIsLoading(true)
    axiosInstance.get('/api/tickets/v1/' + ticketId)
        .then((response) => {
            setTicket(response.data.data);
            setIsLoading(false)
        }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            //setErrors(error.response.data.errors)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            //setErrors(error)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.error('Error:', error);
        }
        setIsLoading(false)
    })
}

//update status, prioritis or category id
export const ticketUpdate = (ticketId, id, field, {ticketUrl, setTickets, setPaginationLink, setMessage, setIsLoading, dispatch}) => {
    setIsLoading(true)
    axiosInstance.put('/api/tickets/v1/' + ticketId, {[field]: id}, {
        'headers': {
            "content-type": 'application/x-www-form-urlencoded',
        }
    }).then((response) => {
        if(ticketUrl) {
            retrieveTicketList({ticketUrl, setTickets, setPaginationLink, setIsLoading})
        }

        if(field == 'priority_id'){
            dispatch(retrievePriorityRedux())
        }else{
            dispatch(retrieveStatusesRedux())
        }

        setMessage({'message': response.data.message, type: 'success'})
        setIsLoading(false)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            //setErrors(error.response.data.errors)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            //setErrors(error)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.error('Error:', error);
        }
        setIsLoading(false)
    })
}

//retrieve ticket history
export const ticketHistory = (ticketId, {setHistory, setMessage}) => {
   axiosInstance.get('/api/tickets/v1/' + ticketId + '/history')
       .then((response) => {
            setHistory(response.data.data);
        }).catch((error) => {
       if (error.response && error.response.status === 422) {
           // Handle the 422 response (validation error)
           //setErrors(error.response.data.errors)
           setMessage({'message': error.response.data.message, type: 'danger'})
           console.log('Validation Errors:', error.response.data);
       } else {
           // Handle other errors (e.g., network issues)
           //setErrors(error)
           setMessage({'message': error.response.data.message, type: 'danger'})
           console.error('Error:', error);
       }
   })
}
