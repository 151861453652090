import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import {addNewTicket} from "../../Api/Tickets/FetchData";
import React, {useState} from "react";
import {Loading} from "../Elements/Loading";
import {useDispatch} from "react-redux";

const ModalAddTicket = ({show, setShow, setTickets, setPaginationLink, setMessage, categories, priorities, tags, ticketUrl, isAdmin, roles}) => {
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({})
    const userId = JSON.parse(localStorage.getItem('userData')).id ?? null;
    const [loading, setIsLoading] = useState(false)

    const handleClose = () => {
        setShow(false)
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const form = document.getElementById('addTicketForm');
        const formData = new FormData(form);
        const addMessage = document.getElementById('messageTicket').value !== '';

        addNewTicket(formData, addMessage, {setTickets, setPaginationLink, setMessage, ticketUrl, setShow, setErrors, setIsLoading, dispatch})
    }

    const tagList = tags.map(tag => {
        return {value: tag.id, label: tag.name}
    })

    //on change subcategory set category id
    const handleCategoryChange = (e) => {
        document.getElementById('category_id').value = e.target.options[e.target.selectedIndex].getAttribute('data-category-id');
    }

    return (
        <Modal show={show} className={'modal-lg'} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Inserisci un nuovo ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="addTicketForm" onSubmit={handleOnSubmit} encType={'multipart/form-data'}>
                    <div className="row">
                        <div className="col-md-4"><label>Titolo</label>
                            <input className={'form-control-sm form-control'} name={'title'} type={'text'}/>
                            { errors.title && (<span className={"text-danger"}>{errors.title}</span>) }
                        </div>
                        <div className="col-md-4">
                            <label>Categoria</label>
                            <select name={'subcategory_id'} className={'form-select-sm form-select'} onChange={handleCategoryChange}>
                                <option value={''}>-- Seleziona --</option>
                                {categories.map((category) => {
                                    return (
                                        <optgroup key={category.id} label={category.name}>>
                                            {category.subcategories.map((subcategory) => {
                                                return <option key={subcategory.id} data-category-id={subcategory.category_id} value={subcategory.id}>{subcategory.name}</option>
                                            })}
                                        </optgroup>
                                    )
                                })}
                            </select>

                            { errors.subcategory_id && (<span className={"text-danger"}>{errors.subcategory_id}</span>) }
                            { errors.category_id && (<span className={"text-danger"}>{errors.category_id}</span>) }
                            <input type={'hidden'} id={'category_id'} name={'category_id'} value={0} />
                        </div>
                        <div className="col-md-4">
                            <label>Priorità</label>
                            <select name={'priority_id'} className={'form-select-sm form-select'}>
                                <option value={''}>-- Seleziona --</option>
                                {priorities.map((priority) => {
                                    return (
                                        <option key={priority.id} name={'priority_id'} value={priority.id}>{priority.name}</option>
                                    )
                                })}
                            </select>

                            { errors.priority_id && (<span className={"text-danger"}>{errors.priority_id}</span>) }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <label>Descrizione</label>
                            <textarea className={'form-control'} name={'description'} type={'text'} />
                            { errors.description && (<span className={"text-danger"}>{errors.description}</span>) }
                        </div>
                    </div>

                    <div className="row">
                        { isAdmin && <div className="col-md-12">
                            <label>Tags</label>
                            <Select classNamePrefix="react-select" name={'tag_ids[]'} options={tagList} isMulti styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: '#dedede',
                                }),
                                multiValueLabel: (styles, {data}) => ({
                                    ...styles,
                                    backgroundColor: '#333',
                                    color: '#fff'
                                }),
                                multiValueRemove: (styles, {data}) => ({
                                    ...styles,
                                    backgroundColor: '#333',
                                    color: '#fff'
                                }),
                            }} className={'form-select-sm form-control p-0'}></Select>
                            { errors.tag_ids && (<span className={"text-danger"}>{errors.tag_ids}</span>) }
                        </div> }

                        <div className="col-md-12">
                            <label>Messaggio</label>
                            <textarea className={'form-control'} id={'messageTicket'} name={'message'}/>
                        </div>

                        <div className="col-md-12">
                            <label>Carica File</label>
                            <input accept={".docx, .pdf, .jpeg, .jpg"} className={'form-control'} name={'files[]'} type={'file'} multiple={'multiple'}/>
                        </div>
                    </div>
                    <input className={'form-control-sm form-control'} type={'hidden'} name={'user_id'} value={userId} />

                    <div className="row">
                        <div className="col-md-12 text-end">
                            <button className={'btn btn-primary mt-2'}> Invia</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
            { loading && <Loading /> }
        </Modal>
    )
}

export default ModalAddTicket