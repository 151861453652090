import { Link } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";

const GoBack = ({url}) => {
    return (
        <Link to={url}>
            <IoIosArrowBack /> Indietro
        </Link>
    );
}

export default GoBack;