import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

const AuthContext = React.createContext();

export const AuthContextProvider = (props) => {
    const navigate = useNavigate()
    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated'));
    const [user, setUser] = useState([]);
    const [jwt, setJwt] = useState('');
    const [sanctum, setSanctum] = useState('');
    const [expiration, setExpiration] = useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id')

    const login = (userData, sanctum , jwt, expiration) => {
        setUser(userData);
        setJwt(jwt);
        setSanctum(sanctum);
        setExpiration(expiration);

        const roles = JSON.parse(userData).roles.map((role) => role.name)

        if (!!sanctum && !!jwt && !!expiration) {
            localStorage.setItem('sanctum', sanctum);
            localStorage.setItem('jwt', jwt);
            localStorage.setItem('userData', userData)
            localStorage.setItem('isAuthenticated', true);
            localStorage.setItem('expiration', expiration)
            localStorage.setItem('roles', JSON.stringify(roles))
            setIsAuthenticated(localStorage.getItem('isAuthenticated'));
        }
    }

    const logout =() => {
        setIsAuthenticated(false);
        setUser(null);
        setJwt('');
        localStorage.clear();
    }

    //controlla lo stato del token, se è autenticato e se è un ruolo esistente per reindirizzare alla home
    useEffect(() => {
        let expiration = localStorage.getItem('expiration');
        let isAuthenticated = localStorage.getItem('isAuthenticated');

        if(isAuthenticated !== 'true' || (expiration === null || new Date() > new Date(expiration))){
            logout()
        }else{
            id ? navigate('/ticket/' + id) : navigate('tickets')
        }
    }, []);

    return (
        <AuthContext.Provider value={{login, logout, isAuthenticated, user, sanctum, jwt, expiration}}>
            {props.children}
        </AuthContext.Provider>
    );
}

export default AuthContext