import {TailSpin} from "react-loader-spinner";

export const Loading = () => {
    console.log('Loading...')
    return <TailSpin
        height="80"
        width="80"
        color="#E94E1B"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
        visible={true}/>
}