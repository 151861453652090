// reducers.js
import { FETCH_STATUSES_REQUEST, FETCH_STATUSES_SUCCESS, FETCH_STATUSES_FAILURE} from "../ActionTypes/ActionTypeStatuses";

const initialState = {
    loadingStatuses: false,
    dataStatuses: [],
    errorStatuses: null,
};

const statusReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATUSES_REQUEST:
            return {
                ...state,
                loadingStatuses: true,
            };
        case FETCH_STATUSES_SUCCESS:
            return {
                ...state,
                loadingStatuses: false,
                dataStatuses: action.payload,
                errorStatuses: null,
            };
        case FETCH_STATUSES_FAILURE:
            return {
                ...state,
                loadingStatuses: false,
                errorStatuses: action.payload,
            };
        default:
            return state;
    }
};

export default statusReducer;
