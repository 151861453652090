import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";

import App from "../App";
import List from "../Pages/Tickets/List";
import Detail from "../Pages/Tickets/Detail";
import Authentication from "../Pages/Auth/Authentication";

export const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path={'/'} element={<App/>}>
                <Route path='/auth' element={<Authentication />}></Route>
                <Route path='/tickets' element={<List />} />
                <Route path="/ticket/:id" element={<Detail />} />
            </Route>
        )
    )
;
