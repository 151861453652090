import React, {useContext} from "react";
import '../../Assets/Css/Header.css'
import {IoMdLogOut} from "react-icons/io";
import {Link} from "react-router-dom";
import AuthContext from "../../Context/authContext";
const Header = () => {
    const {logout} = useContext(AuthContext)

    return (
        <header className={'row rounded-3  mt-2 mb-5 py-3 px-5 row w-100  shadow'}>
            <div></div>
            <div className="col-md-12 align-self-end text-end">
                <span><IoMdLogOut /><Link onClick={() => logout()} to={'/auth'}>Logout</Link></span>


            </div>

        </header>
    )
}

export default Header