// actions.js
import { FETCH_STATUSES_REQUEST, FETCH_STATUSES_SUCCESS, FETCH_STATUSES_FAILURE} from "../ActionTypes/ActionTypeStatuses";
import axiosInstance from "../../Api/AxiosInstance";

//retireve category of tickets with redux
export const retrieveStatusesRedux = () => {
    return (dispatch) => {
        dispatch(fetchStatusesRequest())
        axiosInstance.get('/api/tickets/v1/statuses').then((response) => {
            dispatch(fetchStatusesSuccess(response.data.data))
        }).catch((error) => {
            dispatch(fetchStatusesFailure(error.response.data))
        })
    }
}

export const fetchStatusesRequest = () => ({
    type: FETCH_STATUSES_REQUEST,
});

export const fetchStatusesSuccess = (data) => ({
    type: FETCH_STATUSES_SUCCESS,
    payload: data,
});

export const fetchStatusesFailure = (error) => ({
    type: FETCH_STATUSES_FAILURE,
    payload: error,
});
