import Alert from 'react-bootstrap/Alert';
const CustomAlert = ({message}) => {

    return (
        message && (
            <Alert variant={message.type} dismissible>
                {message.message}
            </Alert>
        )
    );
}

export default CustomAlert;