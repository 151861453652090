import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {RiMenu5Fill} from 'react-icons/ri'
import '../../Assets/Css/Sidebar.css'
import {Badge, Col, Row} from "react-bootstrap";
import {useSelector} from "react-redux";

const Sidebar = () => {
    const [show, setShow] = useState(true);
    const [priorities, setPriorities] = useState([])
    const [categories, setCategories] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [activeAll, setActiveAll] = useState(true)
    const [activeCategory, setActiveCategory] = useState(0);
    const [activeStatus, setActiveStatus] = useState(0);
    const [activePriority, setActivePriority] = useState(0);
    //redux selector
    const {dataCategories} = useSelector((state) => state.category);
    const {dataPriorities} = useSelector((state) => state.priority);
    const {dataStatuses} = useSelector((state) => state.status);

    //retrieve necessary information list of tags, priority, category and operators at inizialization component
    useEffect(() => {
        setCategories(dataCategories)
        setPriorities(dataPriorities)
        setStatuses(dataStatuses)
    }, [dataStatuses, dataCategories, dataPriorities])

    const toggleSidebar = () => {
        if (show) {
            setShow(false);
            // document.querySelector('.sidebar').style.display = 'none'
            document.querySelector('.sidebar').classList.add('hiddenSide');
        } else {
            setShow(true);
            document.querySelector('.sidebar').classList.remove('hiddenSide')
        }
    }
    const mouseEnter = () => {
        document.querySelector('.sidebar').classList.add('hoverShow');
    }
    const mouseLeave = () => {
        document.querySelector('.sidebar').classList.remove('hoverShow');
    }

    return (
        <>
            <div className={'py-4 px-2 shadow  rounded-end-4 sidebar bg-dark'}>
                <div className={`openSidebar bg-dark position-absolute p-2 text-white show `}>
                    <a href={'#'} onClick={toggleSidebar}> <RiMenu5Fill/> </a>
                </div>
                <div className={'test'} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
                    <div className="col-md-12 justify-content-start text-center text-white py-4 px-3"><h2>WeTickets</h2>
                    </div>
                    <nav className={'nav col-md-12 d-block text-start p-2'}>
                        <div className={'navSection mb-5 '}>
                            <h6 className={'text-white text-uppercase'}>Tickets</h6>
                            <Link onClick={() => {
                                setActiveStatus(0)
                                setActivePriority(0)
                                setActiveCategory(0)
                                setActiveAll(true)
                            }} to={'/tickets'} state={''} className={`nav-link text-white border-bottom ${activeAll ? 'active' : ''}`}>Tutti</Link>
                        </div>
                        <div className={'navSection mb-5'}>
                            <h6 className={'text-white text-uppercase'}>Categoria</h6>
                                {categories && categories.length > 0 ? (
                                    categories.map((category) => {
                                        return <Row key={category.id} className={`${activeCategory === category.id ? 'active' : ''}`}>
                                            <Col sm={8} className={'nav-link white border-bottom'}>
                                                <Link onClick={() => {
                                                    setActiveCategory(category.id)
                                                    setActiveAll(false)
                                                }} to={'/tickets'} state={[{type: 'category', key: 'filters[category_id]', value: category.id}]}>{category.name}</Link>
                                            </Col>
                                            <Col sm={4} className={'d-flex align-content-end justify-content-end nav-link white border-bottom'}><Badge bg={"secondary"}>{category.tickets_count}</Badge></Col>
                                        </Row>
                                    })
                                ) : false}
                        </div>
                        <div className={'navSection mb-5'}>
                            <h6 className={'text-white text-uppercase'}>Stato</h6>
                            {statuses && statuses.length > 0 ? (
                                statuses.map((status) => {
                                    return <Row key={status.id} className={`${activeStatus === status.id ? 'active' : ''}`}>
                                        <Col sm={8} className={'nav-link white border-bottom'}>
                                            <Link  onClick={() => {
                                                setActiveStatus(status.id)
                                                setActiveAll(false)
                                            }} to={'/tickets'} state={[{type: 'status', key: 'filters[status_id]', value: status.id}]}>{status.name}</Link>
                                        </Col>
                                        <Col sm={4} className={'d-flex align-content-end justify-content-end nav-link white border-bottom'}><Badge bg={"secondary"}>{status.tickets_count}</Badge></Col>
                                    </Row>
                                })
                            ) : false}
                        </div>
                        <div className={'navSection mb-5'}>
                            <h6 className={'text-white text-uppercase'}>Priorità</h6>
                            {priorities && priorities.length > 0 ? (
                                priorities.map((priority) => {
                                    return <Row key={priority.id} className={`${activePriority === priority.id ? 'active' : ''}`}>
                                        <Col sm={8} className={'nav-link white border-bottom'}>
                                            <Link  onClick={() => {
                                                setActivePriority(priority.id)
                                                setActiveAll(false)
                                            }} to={'/tickets'} state={[{type: 'priority', key: 'filters[priority_id]', value: priority.id}]}>{priority.name}</Link>
                                        </Col>
                                        <Col sm={4} className={'d-flex align-content-end justify-content-end nav-link white border-bottom'}><Badge bg={"secondary"}>{priority.tickets_count}</Badge></Col>
                                    </Row>
                                })
                            ) : false}
                        </div>
                    </nav>
                </div>
            </div>

        </>
    )
}

export default Sidebar