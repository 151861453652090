//retrieve priority of tickets
export const downloadAttachments = (id, filename, {setMessage}) => {
    fetch(process.env.REACT_APP_BASE_URL + '/api/tickets/v1/attachments/' + id + '/download', {
        method: 'GET',
        headers: {
            "X-JWT-Token": localStorage.getItem('jwt'),
            "Authorization": "Bearer " + localStorage.getItem('sanctum')
        },
    }).then((response) => response.blob())
        .then((data) => {
            const blob = new Blob([data], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        })
        .catch((error) => {
            console.log(error);
            setMessage({'message': 'Errore generale. Contatta l\' amministratore!', type: 'danger'})
        });
}