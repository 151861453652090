// reducers.js
import { FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE} from "../ActionTypes/ActionTypeCategories";

const initialState = {
    loadingCategories: false,
    dataCategories: [],
    errorCategories: null,
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES_REQUEST:
            return {
                ...state,
                loadingCategories: true,
            };
        case FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                loadingCategories: false,
                dataCategories: action.payload,
                errorCategories: null,
            };
        case FETCH_CATEGORIES_FAILURE:
            return {
                ...state,
                loadingCategories: false,
                errorCategories: action.payload,
            };
        default:
            return state;
    }
};

export default categoryReducer;
