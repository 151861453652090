import Select from "react-select";
import React, {useRef, useState} from "react";
import {assignOperators, removeOperator} from "../../../Api/Tickets/FetchOperators";
import {LiaUserCircle} from "react-icons/lia";
import {Container} from "react-bootstrap";

const SelectOperator = (props) => {
    const ticket = props.ticket;
    const rowIndex = props.rowIndex;
    const [message, setMessage] = useState()
    const [loading, setIsLoading] = useState(false)
    const [focusedRowIndex, setFocusedRowIndex] = useState(-1);
    const [focusedColumnIndex, setFocusedColumnIndex] = useState(-1);
    const selectRef = useRef(null);

    //generate new operator ticket
    const [newOperators, setNewOperators] = useState(
        ticket.operators.map((element) => {
            return {label: element.username, value: element.id}
        })
    )

    const handleFocus = (rowIndex, columnIndex) => {
        setFocusedRowIndex(rowIndex);
        setFocusedColumnIndex(columnIndex);
    };

    const handleBlur = () => {
        setFocusedRowIndex(-1);
        setFocusedColumnIndex(-1);
    };

    //assign operator to ticket
    const handleSelectAdd = (newValue, ticketId) => {
        assignOperators(newValue[newValue.length - 1].value, {ticketId, setMessage, setIsLoading});
        setNewOperators(newValue)
    }

    //Remove tag or operator by ticket id
    const handleSelectDelete = (event, ticketId, newValue) => {
        removeOperator(ticketId, [event.value], {setMessage, setIsLoading});
        setNewOperators(newValue)
    }

    //create object to pass in a select
    const operatorsList = props.operators.map(i => {
        return {value: i.id, label: i.username}
    })

    return (
        rowIndex === focusedRowIndex && focusedColumnIndex === 0 ? (
            <div ref={selectRef} className={'inline-block'} onBlur={handleBlur}>
                <Select
                    classNamePrefix="react-select form-plaint"
                    onFocus={() => handleFocus(rowIndex, 0)}
                    onChange={(newValue, actionMeta) => {
                        switch (actionMeta.action) {
                            case 'remove-value':
                                handleSelectDelete(actionMeta.removedValue, ticket.id, newValue);
                                break;
                            case 'select-option':
                                handleSelectAdd(newValue, ticket.id);
                                break;
                        }
                    }}
                    data-name={'operator_id'}
                    options={operatorsList}
                    defaultValue={newOperators}
                    isMulti
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: '1px solid #dedede',
                        }),
                        multiValueLabel: (styles, { data }) => ({
                            ...styles,
                            backgroundColor: '#333',
                            color: '#fff',
                        }),
                        multiValueRemove: (styles, { data }) => ({
                            ...styles,
                            backgroundColor: '#333',
                            color: '#fff',
                        }),
                        dropdownIndicator: (styles, {}) => ({
                            ...styles,
                            display: 'none',
                        }),
                    }}
                    isClearable={false}
                    placeholder={'Seleziona Operatore'}
                    className={'form-select-sm form-control-plaintext p-0'}
                />
            </div>
        ) : (
            <div onClick={() => {if(props.isAdmin) handleFocus(rowIndex, 0)}} className={'w-100 h-100 d-inline-block'}>
                {
                    newOperators.length > 0
                        ? (newOperators.map(op => {
                            return (
                                <Container key={op.value} className={"text-start"}>
                                    <LiaUserCircle className={'fs-4'}/>
                                    <span key={op.value} className={' mx-1 d-inline-block'}>{op.label}</span>
                                </Container>
                            )
                        }))
                        : (
                            <>
                                <Container className={"text-start"}>
                                    <span className={' mx-1 d-inline-block'}>Non Assegnato</span>
                                </Container>
                            </>
                        )
                }
            </div>
        )
    );
}

export default SelectOperator