import {useLocation, useNavigate} from "react-router-dom";
import {useContext, useEffect, useRef, useState} from "react";
import AuthContext from "../../Context/authContext";
import {getAccessToken, loginAuth} from "../../Api/Auth/Auth";
import '../../Assets/Css/Authentication.css'
import wematicaLogo from '../../Assets/Img/wematica-logo.png'
import {Loading} from "../../Components/Elements/Loading";
import {Button, Form} from "react-bootstrap";

const Authentication = () => {
    const location = useLocation();
    const [serviceLogo, setServiceLogo] = useState('');
    const queryParams = new URLSearchParams(location.search);
    const jwt = queryParams.get('jwt');
    const service = queryParams.get('service')
    const id = queryParams.get('id')
    const [loading, setIsLoading] = useState(false)
    const usernameOrEmail = useRef();
    const password = useRef();
    const navigate = useNavigate()
    const [errors, setErrors] = useState()
    const {login} = useContext(AuthContext)

    //auth and define logo basic
    useEffect(() => {
        jwt && getAccessToken({jwt, service, login, navigate, setErrors, id, setIsLoading})

        import('../../Assets/Img/' + process.env.REACT_APP_SERVICE + '-logo.png')
            .then((image) => {
                setServiceLogo(image.default);
            })
            .catch((error) => {
                console.error('Image import error:', error);
            });
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        loginAuth(usernameOrEmail.current.value, password.current.value, {login, setErrors, navigate, setIsLoading});
    }

    return (
        <>
            <div className="auto-login-container bg-dark">
                <div className="logo-container">
                    <img src={serviceLogo} alt="Service Logo" className="logo" />
                    <img src={wematicaLogo} alt="Wematica Logo" className="logo" />
                </div>

                <h1 className="auto-login-heading">Login</h1>

                <Form>
                    <Form.Group>
                        <Form.Label>Username</Form.Label>
                        <Form.Control ref={usernameOrEmail} type={'text'} name={'usernameOrEmail'} placeholder={'Username'} required={'required'}></Form.Control>
                        <span className={'text-danger text-start'}>{errors && typeof errors === "object" && errors.usernameOrEmail}</span>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control ref={password} type={'password'} name={'password'} placeholder={'Password'} required={'required'}></Form.Control>
                        <span className={'text-danger text-start'}>{errors && typeof errors === "object" && errors.password}</span>
                    </Form.Group>

                    <br></br>

                    <Button variant="primary" onClick={handleSubmit} type="submit">Accedi</Button>
                </Form>
            </div>

            {loading && <Loading /> }
        </>
    );
}

export default Authentication