import {retrieveTicketList} from "./FetchData";
import axiosInstance from "../AxiosInstance";

//retrieve operators can assign to ticket
export const retrieveOperators = ({setOperators, setMessage}) => {
    axiosInstance.get('/api/tickets/v1/operators')
        .then((response) => {
            setOperators(response.data.data);
        }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            //setErrors(error.response.data.errors)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            //setErrors(error)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.error('Error:', error);
        }
    });
}

//assegna operatori al ticket
export const assignOperators = (operatorId, {ticketId, setTickets, ticketUrl, setPaginationLink, setMessage, setIsLoading}) => {
    axiosInstance.post('/api/tickets/v1/' + ticketId + '/assign-operator', {'user_id': operatorId})
        .then((response) => {
            if (ticketUrl) {
                retrieveTicketList({ticketUrl, setTickets, setPaginationLink, setIsLoading});
            }

            setMessage({'message': response.data.message, type: 'success'})
        }).catch((error) => {
            if (error.response && error.response.status === 422) {
                // Handle the 422 response (validation error)
                //setErrors(error.response.data.errors)
                setMessage({'message': error.response.data.message, type: 'danger'})
                console.log('Validation Errors:', error.response.data);
            } else {
                // Handle other errors (e.g., network issues)
                //setErrors(error)
                setMessage({'message': error.response.data.message, type: 'danger'})
                console.error('Error:', error);
        }
    });
}

//rimuove operatori dal ticket
export const removeOperator = (ticketId, operatorId, {setTickets, ticketUrl, setPaginationLink, setMessage, setIsLoading}) => {
    axiosInstance.post('/api/tickets/v1/' + ticketId + '/unassign-operator', {'user_id': operatorId})
        .then((response) => {
                if (ticketUrl) {
                    retrieveTicketList({ticketUrl, setTickets, setPaginationLink, setIsLoading});
                }
        }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            //setErrors(error.response.data.errors)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            //setErrors(error)
            setMessage({'message': error.response.data.message, type: 'danger'})
            console.error('Error:', error);
        }
    });
}