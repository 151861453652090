import axiosInstance from "../AxiosInstance";

//get access token for auto login
export const getAccessToken = ({jwt, service, login, navigate, setErrors, id, setIsLoading}) => {
    setIsLoading(true)
    axiosInstance.get('/api/tickets/v1/' + service + '/getTokens', {
        headers: {
            'Authorization': `Bearer ${jwt}`,
        }
    }).then((response) => {
        // Handle successful response (status code 200)
        login(JSON.stringify(response.data.user), response.data.tokens.sanctum, response.data.tokens.jwt, response.data.jwt_decode.exp.date)
        id !== null ? navigate('/ticket/' + id) : navigate('/tickets')
        setIsLoading(false)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            console.error('Error:', error);
        }
        setIsLoading(false)
    });
}

//login api
export const loginAuth = (usernameOrEmail, password, {login, setErrors, navigate, setIsLoading}) => {
    setIsLoading(true)
    axiosInstance.post('/api/tickets/v1/login', {
        usernameOrEmail: usernameOrEmail,
        password: password
    }).then((response) => {
        // Handle successful response (status code 200)
        login(JSON.stringify(response.data.user), response.data.tokens.sanctum, response.data.tokens.jwt, response.data.jwt_decode.exp.date)
        navigate('/tickets')
        setIsLoading(false)
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            setErrors(error)
            console.error('Error:', error);
        }
        setIsLoading(false)
    });
}