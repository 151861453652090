import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom';
import {removeTicket, retrieveTicketList} from "../../Api/Tickets/FetchData";
import {retrieveTags} from "../../Api/Tickets/FetchTags";
import {retrieveOperators} from "../../Api/Tickets/FetchOperators";
import ModalAddTicket from "../../Components/Modal/ModalAddTicket";
import {Col, Container, Pagination, Row, Spinner} from "react-bootstrap";
import {sanitizeHtml} from "bootstrap/js/src/util/sanitizer";
import {IoMdAddCircle} from "react-icons/io";
import CustomAlert from "../../Components/Elements/CustomAlert";
import {IsAdmin, Roles} from "../../Utils/Roles";
import {TicketTable} from "../../Components/Table/TicketTable";
import {Loading} from "../../Components/Elements/Loading";
import {useDispatch, useSelector} from "react-redux";
import Search from "../../Components/Elements/Search";

const List = () => {
    const dispatch = useDispatch()
    const initialTypeOrder = {'title': 'desc', 'description': 'desc', 'operator': 'desc', 'priority': 'desc', 'status': 'asc', 'user': 'desc'}
    const location = useLocation();
    const [tickets, setTickets] = useState({});
    const [operators, setOperators] = useState([]);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState()
    const [tags, setTags] = useState([]);
    const [priorities, setPriorities] = useState([])
    const [categories, setCategories] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [paginationLink, setPaginationLink] = useState([]);
    const [ticketUrl, setTicketUrl] = useState('/api/tickets/v1');
    const [search, setSearch] = useState({});
    const [typeOrder, setTypeOrder] = useState(initialTypeOrder)
    const [roles, setRoles] = useState();
    const [isAdmin, setIsAdmin] = useState()
    const [ticketDelete, setTicketDelete] = useState()
    const [loading, setIsLoading] = useState(false)
    const [sendSearch, setSendSearch] = useState(false)
    //redux selector
    const {dataCategories} = useSelector((state) => state.category);
    const {dataPriorities} = useSelector((state) => state.priority);
    const {dataStatuses} = useSelector((state) => state.status);

    //retrieve necessary information list of tags, priority, category and operators at inizialization component
    useEffect(() => {
        retrieveTags({setTags, setMessage})
        retrieveOperators({setOperators, setMessage})
        setRoles(Roles)
        setIsAdmin(IsAdmin)
    }, [])

    //retrieve necessary information list of tags, priority, category and operators at inizialization component
    useEffect(() => {
        setCategories(dataCategories)
        setPriorities(dataPriorities)
        setStatuses(dataStatuses)
    }, [dataStatuses, dataCategories, dataPriorities])

    useEffect(() => {
        retrieveTicketList({ticketUrl, setTickets, setPaginationLink, search, setIsLoading})
        setSendSearch(false)
    }, [ticketUrl, search, ticketDelete])

    //settings search filter
    useEffect(() => {
        if(location.state && location.state.length > 0){
            setTicketUrl('/api/tickets/v1?page=1')
            location.state.map((value, index) => {
                setSearch(search => ({
                    ...search,
                    [value.key]: value.value,
                }));
            });
        }else{
            setSearch({'sorts[status_id]': 'asc'});
            setTypeOrder(initialTypeOrder)
        }

    }, [location]);

    //show modal for add ticket
    const handleShow = (e) => {
        e.preventDefault()
        setShow(true)
    }

    //change order
    const handleOrder = (e) => {
        const order = e.currentTarget.getAttribute('data-order');
        const type = e.currentTarget.getAttribute('data-type-order');
        const column = e.currentTarget.getAttribute('data-column');
        const updatedTypes = {...typeOrder, [column]: type === 'desc' ? 'asc' : 'desc'}

        setTypeOrder(updatedTypes)
        setSearch(search => ({
            ...search, [order]: type
        }));
    }

    //Call Api to remove ticket
    const handleDelete = (e) => {
        const ticketId = e.currentTarget.getAttribute('data-id');
        removeTicket(ticketId, {setTickets, ticketUrl, setPaginationLink, setMessage, setIsLoading, dispatch});
        setTicketDelete(ticketId)
    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm={3}>
                        <Search setSearch={setSearch} sendSearch={sendSearch} name={'filters[code]'} placeholder={'Ricerca per codice'} />
                    </Col>
                    <Col sm={3}>
                        <Search setSearch={setSearch} sendSearch={sendSearch} name={'filters[title]'} placeholder={'Ricerca nel titolo'} />
                    </Col>
                    <Col sm={3}>
                        <Search setSearch={setSearch} sendSearch={sendSearch} name={'filters[description]'} placeholder={'Ricerca nella descrizione'} />
                    </Col>
                    <Col sm={1} className={"d-flex align-content-end justify-content-end mb-3"}>
                        <a href="#" className={'btn btn-primary'} onClick={() => setTimeout(() => {setSendSearch(true)}, 500)}>Ricerca</a>
                    </Col>
                    <Col sm={1} className={"d-flex align-content-end justify-content-end mb-3"}>
                        <a href="" onClick={handleShow}><IoMdAddCircle className={'fs-2'} title={'Aggiungi Detail'} /></a>
                    </Col>
                </Row>
            </Container>

            <CustomAlert message={message} />
            <TicketTable tickets={tickets} tags={tags} statuses={statuses} operators={operators} priorities={priorities} handleDelete={handleDelete} typeOrder={typeOrder} handleOrder={handleOrder} roles={roles} isAdmin={isAdmin}/>

            <div>
                <Pagination>
                    {
                        paginationLink.map((element, index) => {
                            if(element.url != null) return (
                                <Pagination.Item key={index} active={element.active} href={null} onClick={() => setTicketUrl(element.url)}>
                                    {sanitizeHtml(element.label)}
                                </Pagination.Item>
                            )
                        })
                    }
                </Pagination>
            </div>

            <ModalAddTicket
                show={show}
                setShow={setShow}
                setTickets={setTickets}
                setPaginationLink={setPaginationLink}
                setMessage={setMessage}
                categories={categories}
                priorities={priorities}
                tags={tags}
                ticketUrl={ticketUrl}
                isAdmin={isAdmin}
                roles={roles}
            />

            { loading && <Loading /> }
        </>
    )
}

export default List