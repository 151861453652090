import {retrieveTicketList} from "./FetchData";
import axiosInstance from "../AxiosInstance";

//Api to assign tags for ticket
export const assignTags = ({ticketId, setTickets, assignTag, ticketUrl, setPaginationLink, setMessage, setIsLoading}) => {
    axiosInstance.post('/api/tickets/v1/' + ticketId + '/add-tags', {
        'tags': assignTag
    }).then((response) => {
                if (ticketUrl) {
                    retrieveTicketList({ticketUrl, setTickets, setPaginationLink, setIsLoading});
                }

                setMessage({'message': response.data.message, type: 'success'})
        }).catch((error) => {
            if (error.response && error.response.status === 422) {
                // Handle the 422 response (validation error)
                //setErrors(error.response.data.errors)
                console.log('Validation Errors:', error.response.data);
            } else {
                // Handle other errors (e.g., network issues)
                //setErrors(error)
                console.error('Error:', error);
            }
    });
}

//Retrieve tags for ticket
export const retrieveTags = ({setTags, setMessage}) => {
    axiosInstance.get('/api/tickets/v1/tags').then((response) => {
                setTags(response.data.data);
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            //setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            //setErrors(error)
            console.error('Error:', error);
        }
    });
}

export const removeTag = (ticketId, tagId, {setTickets, ticketUrl, setPaginationLink, setMessage, setIsLoading}) => {
    axiosInstance.post('/api/tickets/v1/' + ticketId + '/remove-tags', {
        'tags': tagId
    }).then((response) => {
            if(ticketUrl){
                retrieveTicketList({ticketUrl, setTickets, setPaginationLink, setIsLoading})
            }

            setMessage({'message': response.data.message, type: 'success'})
    }).catch((error) => {
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            //setErrors(error.response.data.errors)
            console.log('Validation Errors:', error.response.data);
        } else {
            // Handle other errors (e.g., network issues)
            //setErrors(error)
            console.error('Error:', error);
        }
    });
}