import React, {useContext, useEffect} from "react";
import {Outlet} from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import '../../Assets/Css/Custom.css';
import AuthContext from "../../Context/authContext";
import Authentication from "../../Pages/Auth/Authentication";
import {useDispatch} from "react-redux";
import {retrieveCategoryRedux} from "../../Redux/Actions/ActionCategory";
import {retrievePriorityRedux} from "../../Redux/Actions/ActionPriority";
import {retrieveStatusesRedux} from "../../Redux/Actions/ActionStatus";

const Layout = () => {
    const {isAuthenticated} = useContext(AuthContext)
    const dispatch = useDispatch();

    useEffect(() => {
        if(isAuthenticated){
            dispatch(retrieveCategoryRedux());
            dispatch(retrievePriorityRedux());
            dispatch(retrieveStatusesRedux());
        }
    }, [dispatch, isAuthenticated]);

    return (
        isAuthenticated ? (
            <>
                <div className="container-fluid d-flex mx-0 px-0">
                <Sidebar />
                    <div className="container-fluid post px-5">
                        <div>
                            <Header />
                        </div>

                        <div>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </>
        ) : (
           <Authentication />
        )
    )
}

export default Layout