// reducers.js
import { FETCH_PRIORITIES_REQUEST, FETCH_PRIORITIES_SUCCESS, FETCH_PRIORITIES_FAILURE} from "../ActionTypes/ActionTypePriorities";

const initialState = {
    loadingPriorities: false,
    dataPriorities: [],
    errorPriorities: null,
};

const priorityReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRIORITIES_REQUEST:
            return {
                ...state,
                loadingPriorities: true,
            };
        case FETCH_PRIORITIES_SUCCESS:
            return {
                ...state,
                loadingPriorities: false,
                dataPriorities: action.payload,
                errorPriorities: null,
            };
        case FETCH_PRIORITIES_FAILURE:
            return {
                ...state,
                loadingPriorities: false,
                errorPriorities: action.payload,
            };
        default:
            return state;
    }
};

export default priorityReducer;
