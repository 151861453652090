//format data for send to api
export const formatData = (date) => {
    if(date instanceof Date){
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    }
}

//format data on it language
export const formatDataIt = (date) => {
    if(date instanceof Date){
        const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits for month

        return day + '/' + month + '/' + date.getFullYear();
    }
}

export const formatDataItWithTime = (date) => {
    if(date instanceof Date){
        const day = ('0' + date.getDate()).slice(-2); // Ensure two digits for day
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ensure two digits for month
        const hours = ('0' + (date.getHours())).slice(-2); // Ensure two digits for hours
        const minutes = ('0' + (date.getMinutes())).slice(-2); // Ensure two digits for minutes
        const seconds = ('0' + (date.getSeconds())).slice(-2); // Ensure two digits for seconds

        return day + '/' + month + '/' + date.getFullYear() + ' ' + hours + ':' + minutes + ':' + seconds;
    }
}