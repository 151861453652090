// actions.js
import { FETCH_PRIORITIES_REQUEST, FETCH_PRIORITIES_SUCCESS, FETCH_PRIORITIES_FAILURE} from "../ActionTypes/ActionTypePriorities";
import axiosInstance from "../../Api/AxiosInstance";

//retireve category of tickets with redux
export const retrievePriorityRedux = () => {
    return (dispatch) => {
        dispatch(fetchPrioritiesRequest())
        axiosInstance.get('/api/tickets/v1/priorities').then((response) => {
            dispatch(fetchPrioritiesSuccess(response.data.data))
        }).catch((error) => {
            dispatch(fetchPrioritiesFailure(error.response.data))
        })
    }
}

export const fetchPrioritiesRequest = () => ({
    type: FETCH_PRIORITIES_REQUEST,
});

export const fetchPrioritiesSuccess = (data) => ({
    type: FETCH_PRIORITIES_SUCCESS,
    payload: data,
});

export const fetchPrioritiesFailure = (error) => ({
    type: FETCH_PRIORITIES_FAILURE,
    payload: error,
});
