// actions.js
import { FETCH_CATEGORIES_REQUEST, FETCH_CATEGORIES_SUCCESS, FETCH_CATEGORIES_FAILURE} from "../ActionTypes/ActionTypeCategories";
import axiosInstance from "../../Api/AxiosInstance";

//retireve category of tickets with redux
export const retrieveCategoryRedux = () => {
    return (dispatch) => {
        dispatch(fetchCategoriesRequest())
        axiosInstance.get('/api/tickets/v1/categories').then((response) => {
            dispatch(fetchCategoriesSuccess(response.data.data))
        }).catch((error) => {
            dispatch(fetchCategoriesFailure(error.response.data))
        })
    }
}

export const fetchCategoriesRequest = () => ({
    type: FETCH_CATEGORIES_REQUEST,
});

export const fetchCategoriesSuccess = (data) => ({
    type: FETCH_CATEGORIES_SUCCESS,
    payload: data,
});

export const fetchCategoriesFailure = (error) => ({
    type: FETCH_CATEGORIES_FAILURE,
    payload: error,
});
