import Select from "react-select";
import React, {useEffect, useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {ticketUpdate} from "../../../Api/Tickets/FetchData";
import { useDispatch } from 'react-redux';

const SelectStatus = (props) => {
    const dispatch = useDispatch()
    const ticket = props.ticket;
    const rowIndex = props.rowIndex;
    const [message, setMessage] = useState()
    const [status, setStatus] = useState({"value": ticket.status.id, "label": ticket.status.name});
    const [focusedRowIndex, setFocusedRowIndex] = useState(-1);
    const [focusedColumnIndex, setFocusedColumnIndex] = useState(-1);
    const selectRef = useRef(null);
    const [loading, setIsLoading] = useState(false)

    useEffect(() => {
        setStatus({"value": ticket.status.id, "label": ticket.status.name})
    }, [ticket]);

    const handleFocus = (rowIndex, columnIndex) => {
        setFocusedRowIndex(rowIndex);
        setFocusedColumnIndex(columnIndex);
    };

    const handleBlur = () => {
        setFocusedRowIndex(-1);
        setFocusedColumnIndex(-1);
    };

    //assign tag for ticket (not save)
    const handleSelectAdd = (newValue, ticketId) => {
        ticketUpdate(ticketId, newValue.value, 'status_id', {setMessage, setIsLoading, dispatch});
        setStatus(newValue)
    }

    //create object to pass in a select
    const statusesList = props.statuses.map(status => {
        return {value: status.id, label: status.name}
    })

    return (
        rowIndex === focusedRowIndex && focusedColumnIndex === 3  ? (
            <div ref={selectRef} className={'inline-block'} onBlur={handleBlur}>
                <Select classNamePrefix="react-select form-plaint"
                        onChange={(
                            newValue,
                            actionMeta
                        ) => {
                            switch (actionMeta.action) {
                                case 'select-option':
                                    handleSelectAdd(newValue, ticket.id)
                                    break;
                            }}}
                        key={ticket.id} data-name={'status_id'} options={statusesList} defaultValue={status}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        isClearable={false} placeholder={'Seleziona Stato'} className={'form-select-sm form-control-plaintext p-0'}>
                </Select>
            </div>
        ) : (
            <div onClick={() => {if(props.isAdmin) handleFocus(rowIndex, 3)}} className={'w-100 h-100 d-inline-block'}>
                {
                    <Container className={"text-start"}>
                        <span key={status.value} className={'badge mx-1 bg-secondary d-inline-block'}>{status.label}</span>
                    </Container>
                }
            </div>
        )
    );
}

export default SelectStatus