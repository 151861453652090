import {OverlayTrigger, Tooltip} from "react-bootstrap";
import React from "react";

const renderTooltip = (text) => (
    <Tooltip id="tooltip">{text}</Tooltip>
);

const CustomOverlay = ({text}) => {
    return (
        <OverlayTrigger overlay={renderTooltip(text)}>
            <span className={"d-inline-block text-truncate"} style={{ maxWidth: "250px" }}>{text}</span>
        </OverlayTrigger>
    )
}

export default CustomOverlay